import React from 'react';
import AuthPanel from '../components/AuthPanel';

const Auth = () => {
    return (
        <main>
        <div className='authen'>
            <AuthPanel/>
                </div>
        </main>
    );
};

export default Auth;