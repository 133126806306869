import React from 'react';
// import Footer from './Footer';

const MessagePanel = () => {
    return (
        <>
            <p style={{textAlign:"center"}}><img src='../img/logo-fond-blanc.svg' alt='logoLAZ' /></p>
            <h3 style={{textAlign:"center"}}>Conversez directement avec un de nos experts en conseils</h3>
        </>
    );
};

export default MessagePanel;