import React from 'react';

const Histo = () => {
    return (
        <>
            <h3 style={{textAlign:"center"}}>Historique vide pour le moment</h3>
        </>
    );
};

export default Histo;